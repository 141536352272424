import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Nav from './Nav';
import index from './pages';
import decode from './pages/decode';
import experiments from './pages/experiments';
import notFound from './pages/not-found';

import './App.scss';

const App: React.FC = () => (
  <Router>
    <Nav />
    <div className="app text-center">
      <div className="container">
        <Switch>
          <Route path="/" exact component={index} />
          <Route path="/decode" component={decode} />
          <Route path="/experiments" component={experiments} />
          <Route component={notFound} />
        </Switch>
      </div>
    </div>
  </Router>
);

export default App;
