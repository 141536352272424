import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock, faFlask } from '@fortawesome/free-solid-svg-icons';

const Nav: React.FC = () => (
  <div className="site-header sticky-top text-white bg-dark text-center">
    <div className="container">
      <ul className="nav d-inline-block">
        <li className="nav-item d-inline-block">
          <Link className="nav-link text-light" to="/">
            <FontAwesomeIcon className="mr-2" icon={faLock} />
            Encode
          </Link>
        </li>
        <li className="nav-item d-inline-block">
          <Link className="nav-link text-light" to="/decode">
            <FontAwesomeIcon className="mr-2" icon={faUnlock} />
            Decode
          </Link>
        </li>
        <li className="nav-item d-inline-block">
          <Link className="nav-link text-light" to="/experiments">
            <FontAwesomeIcon className="mr-2" icon={faFlask} />
            Experiments
          </Link>
        </li>
      </ul>
    </div>
  </div>
);

export default Nav;
