function pad(num: number, size: number) {
  return `000000000${num}`.substr(-size);
}

function doesWordContainPattern(word: string, pattern: string): boolean {
  if (pattern.length === 0) {
    return true;
  }

  const [head, ...tail] = pattern.split('');

  const index = word.indexOf(head);
  if (index < 0) {
    return false;
  }

  const subString = word.substring(index + 1);

  return doesWordContainPattern(subString, tail.join(''));
}

export default class SimpleWordBank {
  wordArray: Array<string>;

  constructor(wordArray: Array<string>) {
    this.wordArray = wordArray;
  }

  getWordForNumber(number: string) {
    if (number.length < 3) {
      return '';
    }

    const numberInt = parseInt(number, 10);
    if (numberInt < 0 || numberInt > 999) {
      throw Error('wtf');
    }

    return this.wordArray[numberInt];
  }

  getWordsStartingWith(partOfWord: string) {
    return this.wordArray.filter(item => item.indexOf(partOfWord) === 0);
  }

  getWordsContaining(partOfWord: string) {
    return this.wordArray.filter(item => item.indexOf(partOfWord) > 0);
  }

  getWordsFuzzyMatching(partOfWord: string) {
    return this.wordArray.filter(item => doesWordContainPattern(item, partOfWord));
  }

  getNumberForWord(word: string) {
    const index = this.wordArray.indexOf(word);
    if (index < 0) {
      return '';
    }

    return pad(index, 3);
  }
}
