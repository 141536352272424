import React from 'react';

import SimpleWordBank from '../util/SimpleWordBank';
import stringUtils from '../util/stringUtils';
import dictionary from '../mvpDictionary.json';

interface State {
  set1: string;
  set1Word: string;
  set2: string;
  set2Word: string;
  set3: string;
  set3Word: string;
}

export default class IndexPage extends React.Component<{}, State> {
  wordBank: SimpleWordBank;

  set1: React.RefObject<HTMLInputElement>;
  set2: React.RefObject<HTMLInputElement>;
  set3: React.RefObject<HTMLInputElement>;

  constructor(props: {}, context: {}) {
    super(props, context);

    this.wordBank = new SimpleWordBank(dictionary);

    this.state = {
      set1: '',
      set1Word: '',
      set2: '',
      set2Word: '',
      set3: '',
      set3Word: '',
    };

    this.set1 = React.createRef<HTMLInputElement>();
    this.set2 = React.createRef<HTMLInputElement>();
    this.set3 = React.createRef<HTMLInputElement>();
  }

  onFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const sanitizedInput = stringUtils.removeNonNumbers(value);
    const word = this.wordBank.getWordForNumber(sanitizedInput);

    // TODO JSS: Get rid of any here
    this.setState(
      prevState => ({
        [name]: sanitizedInput,
        [`${name}Word`]: word,
      } as any),
    );

    const focusIndex = name === "set1" ? 1 : name === "set2" ? 2 : 3;
    if (sanitizedInput.length === 3) {
      if (focusIndex === 1) {
        if (this.set2.current !== null) {
          this.set2.current.focus();
        }
      } else if (focusIndex === 2) {
        if (this.set3.current !== null) {
          this.set3.current.focus();
        }
      }
    }
  };

  render() {
    const { set1, set1Word, set2, set2Word, set3, set3Word } = this.state;

    return (
      <div className="IndexPage">
        <div className="row">
          <div className="col-sm">
            <h2>Encode</h2>
          </div>
        </div>
        <div className="row">
          <form>
            <div className="form-row">
              <div className="col">
                <input type="text" readOnly className="form-control-plaintext" value="+447" />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="123"
                  name="set1"
                  value={set1}
                  onChange={this.onFormChange}
                  ref={this.set1}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="456"
                  name="set2"
                  value={set2}
                  onChange={this.onFormChange}
                  ref={this.set2}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="789"
                  name="set3"
                  value={set3}
                  onChange={this.onFormChange}
                  ref={this.set3}
                />
              </div>
            </div>
          </form>
          <form>
            <div className="form-row">
              <div className="col">
                <input type="text" readOnly className="form-control-plaintext" value="" />
              </div>
              <div className="col">
                <input type="text" readOnly className="form-control-plaintext" value={set1Word} />
              </div>
              <div className="col">
                <input type="text" readOnly className="form-control-plaintext" value={set2Word} />
              </div>
              <div className="col">
                <input type="text" readOnly className="form-control-plaintext" value={set3Word} />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
