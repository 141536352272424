import React from 'react';
import classNames from 'classnames';
import SimpleWordBank from '../../util/SimpleWordBank';
import stringUtils from '../../util/stringUtils';
import dictionary from '../../mvpDictionary.json';

interface HighlightedMatchProps {
  word: string;
  pattern: string;
}

const HighlightedMatch = (props: HighlightedMatchProps) => {
  const { word, pattern } = props;
  const innerText = [];

  let patternChars = pattern.split('');
  for (let i = 0; i < word.length; i++) {
    const wordChar = word.charAt(i);
    const patternChar = patternChars[0];

    const key = `${word}_${pattern}_${i}_${wordChar}`;

    if (wordChar === patternChar) {
      const span = (
        <span key={key} style={{ color: '#28a745', textDecoration: 'underline' }}>
          {wordChar}
        </span>
      );
      innerText.push(span);
      patternChars = patternChars.slice(1);
    } else {
      const span = (
        <span key={key} style={{ color: '#000000' }}>
          {wordChar}
        </span>
      );
      innerText.push(span);
    }
  }

  return <>''{innerText}</>;
};

interface ExperimentsPageState {
  inputText: string;
}

export default class ExperimentsPage extends React.Component<{}, ExperimentsPageState> {
  wordBank: SimpleWordBank;

  constructor(props: {}, context: {}) {
    super(props, context);

    this.wordBank = new SimpleWordBank(dictionary);

    this.state = {
      inputText: '',
    };
  }

  onInputTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const sanitizedInput = stringUtils.removeNonLetters(value);

    this.setState((prevState, props) => ({ inputText: sanitizedInput }));
  };

  render() {
    const matches = this.state.inputText
      ? this.wordBank.getWordsFuzzyMatching(this.state.inputText)
      : [];

    const isValid = Boolean(this.state.inputText && matches.length);
    const isInvalid = Boolean(this.state.inputText && !matches.length);
    const className = classNames('form-control', { 'is-valid': isValid, 'is-invalid': isInvalid });
    return (
      <div className="DecodePage">
        <div className="row">
          <div className="col-sm">
            <h2>Experiments</h2>
          </div>
        </div>
        <div className="row">
          <form>
            <div className="form-row">
              <input
                type="text"
                className={className}
                placeholder="type here..."
                name="inputText"
                value={this.state.inputText}
                onChange={this.onInputTextChange}
              />
            </div>
          </form>
        </div>
        <div className="row">
          {matches && (
            <ul style={{ textAlign: 'left', listStyleType: 'none' }}>
              {matches.map(match => (
                <li key={match}>
                  <HighlightedMatch word={match} pattern={this.state.inputText} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }
}
