const MATCHES_NON_NUMBER = /[^0-9]/gi;
const MATCHES_NON_LETTER = /[^a-z]/gi;

const removeNonNumbers = (original: string) => {
  const onlyLetters = original.replace(MATCHES_NON_NUMBER, '');
  const max3Digits = onlyLetters.substring(0, 3);

  return max3Digits;
};

const removeNonLetters = (original: string) => {
  const onlyLetters = original.replace(MATCHES_NON_LETTER, '');
  const lowerCase = onlyLetters.toLowerCase();

  return lowerCase;
};

export default { removeNonNumbers, removeNonLetters };
