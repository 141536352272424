import React from 'react';

import SimpleWordBank from '../../util/SimpleWordBank';
import stringUtils from '../../util/stringUtils';
import dictionary from '../../mvpDictionary.json';

interface State {
  set1: string;
  set1Number: string;
  set2: string;
  set2Number: string;
  set3: string;
  set3Number: string;
}

export default class DecodePage extends React.Component<{}, State> {
  wordBank: SimpleWordBank;

  constructor(props: {}, context: {}) {
    super(props, context);

    this.wordBank = new SimpleWordBank(dictionary);

    this.state = {
      set1: '',
      set1Number: '',
      set2: '',
      set2Number: '',
      set3: '',
      set3Number: '',
    };
  }

  onFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const sanitizedInput = stringUtils.removeNonLetters(value);
    const number = this.wordBank.getNumberForWord(sanitizedInput);

    // TODO JSS: Get rid of any here
    this.setState(
      prevState => ({
        [name]: sanitizedInput,
        [`${name}Number`]: number,
      } as any),
    );
  };

  render() {
    const { set1, set1Number, set2, set2Number, set3, set3Number } = this.state;

    return (
      <div className="IndexPage">
        <div className="row">
          <div className="col-sm">
            <h2>Decode</h2>
          </div>
        </div>
        <div className="row">
          <form>
            <div className="form-row">
              <div className="col">
                <input type="text" readOnly className="form-control-plaintext" value="" />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="nameber"
                  name="set1"
                  value={set1}
                  onChange={this.onFormChange}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="nameber"
                  name="set2"
                  value={set2}
                  onChange={this.onFormChange}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="nameber"
                  name="set3"
                  value={set3}
                  onChange={this.onFormChange}
                />
              </div>
            </div>
          </form>
          <form>
            <div className="form-row">
              <div className="col">
                <input type="text" readOnly className="form-control-plaintext" value="+447" />
              </div>
              <div className="col">
                <input type="text" readOnly className="form-control-plaintext" value={set1Number} />
              </div>
              <div className="col">
                <input type="text" readOnly className="form-control-plaintext" value={set2Number} />
              </div>
              <div className="col">
                <input type="text" readOnly className="form-control-plaintext" value={set3Number} />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
